<template>
  <div class="checkout-form">
    <div v-if="isLoadingPage">
      <Loader />
    </div>
    <div class="container-fluid">
      <div class="row mt-5">
        <div class="col-12 d-flex justify-content-center">
          <div class="logo">
            <img :src="logo" alt="NinaFin logo">
          </div>
        </div>
      </div>
      <div class="pretty-card my-5">
        <div>
          <h1>Checkout</h1>
          <form
          @keydown.enter="onNext"
          @submit.prevent="onNext"
          class="text-start" novalidate>
            <div class="row">
              <div class="col">
                <b-form-group
                  class="mb-4"
                  id="input-group-firstName"
                  label="Nome"
                  label-for="input-firstName"
                  :class="{ invalid: !isValidFirstName && showErrorFirstName }"
                >
                  <b-form-input
                    id="input-firstName"
                    v-model="form.firstName"
                    type="text"
                    @blur="showErrorFirstName = true"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    :state="false"
                    :class="{
                      invisible: isValidFirstName || !showErrorFirstName,
                    }"
                  >
                    <i class="fas fa-exclamation-circle"></i> Inserisci un nome valido
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group
                  class="mb-4"
                  id="input-group-lastName"
                  label="Cognome"
                  label-for="input-lastName"
                  :class="{ invalid: !isValidLastName && showErrorLastName }"
                >
                  <b-form-input
                    id="input-lastName"
                    v-model="form.lastName"
                    type="text"
                    @blur="showErrorLastName = true"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    :state="false"
                    :class="{ invisible: isValidLastName || !showErrorLastName }"
                  >
                    <i class="fas fa-exclamation-circle"></i> Inserisci un cognome valido
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <b-form-group
                  class="mb-4"
                  id="input-group-email"
                  label="Email"
                  label-for="input-email"
                  :class="{ invalid: !isValidEmail && showErrorEmail }"
                >
                  <b-form-input
                    id="input-iva"
                    v-model="form.email"
                    type="email"
                    @blur="showErrorEmail = true"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    :state="false"
                    :class="{ invisible: isValidEmail || !showErrorEmail }"
                  >
                    <i class="fas fa-exclamation-circle"></i> Inserisci una email valida
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
            </div>
          </form>
          <small v-if="errorFromServer" class="text-red">
            <i class="fas fa-exclamation-circle"></i>
            <span>
              Errore: {{errorFromServer}}
            </span>
          </small>
        </div>
        <div class="row mt-5">
          <div class="col-8 ml-auto">
            <router-link :to="{ name: 'logout' }">
              <button class="btn btn-secondary mr-3" type="button">Logout</button>
            </router-link>
            <custom-button
              :isLoading="loading"
              :class="{disabled: !isValidForm}"
              label="Compra licenza per un anno"
              @click.prevent.native="onNext"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/views/components/Layout/Loading.vue';
import logo from '@/assets/images/fin-big-logo.svg';
import img from '@/assets/images/illustration.svg';
import Button from '@/views/components/Button.vue';
import {
  isFilled,
  isSafe,
} from '@/helpers/formValidation.js';

export default {
  components: {
    Loader,
    'custom-button': Button,
  },
  data () {
    return {
      errorFromServer: null,
      isLoadingPage: true,
      loading: false,
      logo: logo,
      img: img,

      showErrorFirstName: false,
      showErrorLastName: false,
      showErrorEmail: false,
      showErrorPassword: false,
      showErrorPasswordConfirm: false,

      form: {
        // Step 1
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        passwordConfirm: '',
        productType: this.$route.query.product ? this.$route.query.product : '',
      },
    };
  },
  computed: {
    isValidForm () {
      return true;
    },
    isValidFirstName () {
      return isFilled(this.form.firstName) && isSafe(this.form.firstName);
    },
    isValidLastName () {
      return isFilled(this.form.lastName) && isSafe(this.form.lastName);
    },
    isValidEmail () {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return isFilled(this.form.email) && re.test(this.form.email);
    },
    formIsEmpty: {
      get () {
        return Object.keys(this.form).length === 0;
      },
      set (newValue) {
        return newValue;
      },
    },
  },
  methods: {
    onNext: function () {
      this.errorFromServer = null;
      if (this.isValidForm) {
        this.onSubmit();
      } else {
        this.loading = true;
        if (!this.isValidFirstName) {
          this.showErrorFirstName = true;
        } else if (!this.isValidLastName) {
          this.showErrorLastName = true;
        } else if (!this.isValidEmail) {
          this.showErrorEmail = true;
        }
        setTimeout(() => {
          this.loading = false;
        }, 100);
      }
    },
    onSubmit () {
      if (!this.isValidForm) {
        return;
      }
      this.$log.debug('Checkout payment');
      this.isLoadingPage = true;
      this.$api.buyALicense(this.form)
        .then((res) => {
          this.$log.debug(res.data);
          this.$oauth2.getMe(true)
            .finally(() => {
              this.$router.push({ name: 'dashboard' });
            });
          // todo: Gestire pagina di ok
        })
        .catch((e) => {
          // todo: gestire errori
          this.errorFromServer = e.response.data?.error;
          this.$log.error(e);
        })
        .finally(() => {
          this.isLoadingPage = false;
        });
    },
  },
  mounted () {
    const me = this.$oauth2.me;
    if (!(!(me?.product) || (me?.product?.expired))) {
      this.$router.replace('dashboard');
    }
    this.$api.getBillingAddress()
      .then((res) => {
        this.$log.debug(res.data);
      });
    this.isLoadingPage = false;
  },
};
</script>

<style lang="scss">
.checkout-form {
  .text-red > * {
        color: $danger !important;
      }
  background-image: url(~@/assets/images/bg-setup.jpg);
  background-color: $primary-light;
  background-size: cover;
  align-items: center;
  .container-fluid {
    max-width: 1200px;
  }
}
</style>
